<template>
  <el-card class="box-card top-card">
    <template #header>
      <div class="card-header">
        <span class="SubHead">学习进度</span>
        <a
          class="LinkText"
          :href="`${this.base.ac_url}/myCenter/myCourse?source=learncenter-p&am=${userInfo.token}`"
          target="_blank"
          >前往学习中心</a
        >
      </div>
    </template>
    <el-tabs v-model="activeName">
      <el-tab-pane label="全部课程" name="all">
        <!-- Swiper -->
        <div class="swiper-father" style="position: relative;" v-if="learnData.order != ''">
          <div class="swiper learnSwiper">
            <div class="swiper-wrapper">
              <template v-for="item in learnData.order" :key="item.id">
                <div class="swiper-slide" v-if="item.type == 'live'" @click="goEachDetail(item.type, item.id)">
                  <img class="membercorn" src="@/assets/iconImg/learn_membercorn.png" v-if="item.is_member == 1" />
                  <img :src="item.thumb" alt="" />
                  <div class="body">
                    <h3 :title="item.title">{{ item.title }}</h3>
                    <div class="live_status">
                      <span v-if="item.pay_status == 0">未报名</span>
                      <template v-else>
                        <span v-if="item.status == 0">已报名</span>
                        <span v-else-if="item.status == 1">直播中</span>
                        <span v-else>已结束</span>
                      </template>
                    </div>
                    <div class="time_status">
                      <span class="showTime" v-if="item.status == 0">{{ item.time }}</span>
                      <span v-else-if="item.status == 1">进入直播间<i class="iconfont icon-ylright"></i></span>
                      <span v-else>查看回顾<i class="iconfont icon-ylright"></i></span>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide" v-if="item.type == 'course'" @click="goEachDetail(item.type, item.id)">
                  <img class="membercorn" src="@/assets/iconImg/learn_membercorn.png" v-if="item.is_member == 1" />
                  <img :src="item.thumb" alt="" />
                  <div class="body">
                    <h3 :title="item.title">{{ item.title }}</h3>
                    <div v-if="item.pay_status == 0" style="color: #595959;margin-bottom: 32px;">未报名</div>
                    <div v-if="item.pay_status == 0">立即报名<i class="iconfont icon-ylright"></i></div>
                    <template v-else>
                      <el-progress
                        :text-inside="true"
                        :stroke-width="8"
                        :percentage="item.schedule"
                        :status="item.schedule == 100 ? 'success' : ''"
                      />
                      <div class="progress">
                        <span>课程学习进度</span>
                        <span>{{ item.schedule }}%</span>
                      </div>
                      <div class="status" v-if="item.schedule == 100">
                        <img src="@/assets/iconImg/learn_finish.png" alt="" />
                        <span class="finish">已完成</span>
                      </div>
                      <div class="status" v-else>
                        <img src="@/assets/iconImg/learn_learning.png" alt="" class="learning" />
                        学习中
                      </div>
                    </template>
                  </div>
                  <img class="corner" src="@/assets/iconImg/learn_finshcorn.png" v-show="item.schedule == 100" />
                </div>
              </template>
            </div>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
        </div>
        <el-empty v-else description="暂无课程内容"></el-empty>
      </el-tab-pane>
      <el-tab-pane label="会员专享" name="member">
        <div class="swiper-father" style="position: relative;" v-if="learnData.member != ''">
          <div class="swiper learnMemberSwiper">
            <div class="swiper-wrapper">
              <template v-for="item in learnData.member" :key="item.id">
                <div class="swiper-slide" v-if="item.type == 'live'" @click="goEachDetail(item.type, item.id)">
                  <img class="membercorn" src="@/assets/iconImg/learn_membercorn.png" v-if="item.is_member == 1" />
                  <img :src="item.thumb" alt="" />
                  <div class="body">
                    <h3 :title="item.title">{{ item.title }}</h3>
                    <div class="live_status">
                      <span v-if="item.pay_status == 0">未报名</span>
                      <template v-else>
                        <span v-if="item.status == 0">已报名</span>
                        <span v-else-if="item.status == 1">直播中</span>
                        <span v-else>已结束</span>
                      </template>
                    </div>
                    <div class="time_status">
                      <span class="showTime" v-if="item.status == 0">{{ item.time }}</span>
                      <span v-else-if="item.status == 1">进入直播间<i class="iconfont icon-ylright"></i></span>
                      <span v-else>查看回顾<i class="iconfont icon-ylright"></i></span>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide" v-if="item.type == 'course'" @click="goEachDetail(item.type, item.id)">
                  <img class="membercorn" src="@/assets/iconImg/learn_membercorn.png" v-if="item.is_member == 1" />
                  <img :src="item.thumb" alt="" />
                  <div class="body">
                    <h3 :title="item.title">{{ item.title }}</h3>
                    <div v-if="item.pay_status == 0" style="color: #595959;margin-bottom: 32px;">未报名</div>
                    <div v-if="item.pay_status == 0">立即报名<i class="iconfont icon-ylright"></i></div>
                    <template v-else>
                      <el-progress
                        :text-inside="true"
                        :stroke-width="8"
                        :percentage="item.schedule"
                        :status="item.schedule == 100 ? 'success' : ''"
                      />
                      <div class="progress">
                        <span>课程学习进度</span>
                        <span>{{ item.schedule }}%</span>
                      </div>
                      <div class="status" v-if="item.schedule == 100">
                        <img src="@/assets/iconImg/learn_finish.png" alt="" />
                        <span class="finish">已完成</span>
                      </div>
                      <div class="status" v-else>
                        <img src="@/assets/iconImg/learn_learning.png" alt="" class="learning" />
                        学习中
                      </div>
                    </template>
                  </div>
                  <img class="corner" src="@/assets/iconImg/learn_finshcorn.png" v-show="item.schedule == 100" />
                </div>
              </template>
            </div>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
        </div>
        <el-empty v-else description="暂无课程内容"></el-empty>
      </el-tab-pane>
    </el-tabs>
  </el-card>

  <el-row :gutter="24">
    <el-col :span="18">
      <el-card class="box-card bottom-leftCard" style="min-height:340px;">
        <template #header>
          <div class="card-header">
            <span class="SubHead">学习资源</span>
            <a
              class="LinkText"
              :href="`${base.ac_url}/index?source=learncenter-s&am=${userInfo.token}`"
              target="_blank"
              >前往周5出海</a
            >
          </div>
        </template>
        <ul class="learnCate">
          <li v-for="item in learnData.cate" :key="item.name">
            <div class="head">
              <img :src="item.icon" alt="" />
              <span>{{ item.name }}</span>
            </div>
            <div class="titleBox">
              <p class="title" :title="i.title" v-for="i in item.book" :key="i.id" @click="goEachDetail(i.type, i.id)">
                {{ i.title }}
              </p>
            </div>
            <el-button type="text" class="lookMore" @click="lookMore(item.name)"
              ><span>查看更多<i class="iconfont icon-ylright"></i></span
            ></el-button>
          </li>
        </ul>
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card class="box-card" style="min-height:340px;">
        <template #header>
          <div class="card-header">
            <span class="SubHead">最近更新</span>
          </div>
        </template>
        <ul class="updateList">
          <li v-for="item in learnData.renew" :key="item.id" @click="goEachDetail(item.type, item.id)">
            <div class="title" :title="item.title">
              <el-tag size="small" v-if="item.type == 'offline'">活动</el-tag>
              <el-tag size="small" v-else-if="item.type == 'live'">直播</el-tag>
              <el-tag size="small" v-else-if="item.type == 'course'">课程</el-tag>
              <el-tag size="small" v-else-if="item.type == 'book'">文章</el-tag>
              <el-tag size="small" v-else>报告</el-tag>
              {{ item.title }}
            </div>
            <span class="time">{{ item.time }}</span>
          </li>
        </ul>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import Swiper from 'swiper/js/swiper'
import base from '../../request/base'
export default {
  name: 'LearnCenter',
  data() {
    return {
      base: base,
      userInfo: '',
      activeName: 'all',
      learnData: {}
    }
  },
  mounted() {
    this.userInfo = this.$store.getters.userInfo
    this.getLearnData()
    this.$nextTick(() => {
      var swiper = new Swiper('.learnSwiper', {
        slidesPerView: 'auto',
        spaceBetween: 20,
        observer: true,
        observeParents: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      })
      new Swiper('.learnMemberSwiper', {
        slidesPerView: 'auto',
        spaceBetween: 20,
        observer: true,
        observeParents: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      })
    })
  },
  methods: {
    async getLearnData() {
      const { data: res } = await this.$http.post('user/learning_centre')
      console.log(res.data)
      this.learnData = res.data
    },
    goEachDetail(type, id) {
      if (type == 'live') {
        window.open(
          `${this.base.ac_url}/liveDetails?id=${id}&source=course&am=${this.userInfo.token}`,
          '_blank'
        )
      } else if (type == 'course') {
        window.open(
          `${this.base.ac_url}/courseDetails?id=${id}&source=course&am=${this.userInfo.token}`,
          '_blank'
        )
      } else if (type == 'book') {
        window.open(
          `${this.base.ac_url}/articleDetails?id=${id}&source=course&am=${this.userInfo.token}`,
          '_blank'
        )
      } else if (type == 'white_book') {
        window.open(
          `${this.base.ac_url}/whiteBookDetails?id=${id}&source=course&am=${this.userInfo.token}`,
          '_blank'
        )
      } else {
        window.open(
          `${this.base.ac_url}/offLineDetails?id=${id}&source=course&am=${this.userInfo.token}`,
          '_blank'
        )
      }
    },
    lookMore(name) {
      window.open(
        `${this.base.ac_url}/searchList?title=${name}&source=course&am=${this.userInfo.token}`,
        '_blank'
      )
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.el-tabs__item) {
  font-size: 14px !important;
  font-weight: normal !important;
}
a {
  text-decoration: none;
}
.swiper {
  --swiper-navigation-color: #595959; /* 单独设置按钮颜色 */
  --swiper-navigation-size: 20px; /* 设置按钮大小 */
}
.learnSwiper,
.learnMemberSwiper {
  width: 93%;
  overflow: hidden;
  margin: 0 auto;
  padding-right: 15px;
}
.swiper-slide {
  position: relative;
  display: block;
  width: 240px !important;
  height: 312px;
  border: 1px solid #f0f0f0;
  padding: 0;
  overflow: hidden;
  img.corner {
    position: absolute;
    top: -1px;
    right: -1px;
    width: 59px;
    height: auto;
  }
  img {
    height: 160px;
  }
  .body {
    padding: 16px;
    h3 {
      font-size: 16px;
      color: #262626;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0 0 16px;
    }
    .progress {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: #8c8c8c;
      margin: 8px 0 16px;
    }
    .status {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 26px;
        height: auto;
        margin-right: 8px;
      }
      span.finish {
        color: #00f1a2;
      }
    }
    .live_status {
      margin-bottom: 32px;
      span {
        font-size: 14px;
        color: #595959;
      }
    }
    .time_status {
      span.showTime {
        color: #8c8c8c;
      }
      span {
        font-size: 14px;
        color: #2b82ff;
        i {
          font-size: 14px;
          margin-left: 8px;
        }
      }
    }
  }
}
.swiper-slide:hover h3 {
  color: #2b82ff;
}
:deep(.el-progress-bar__innerText) {
  display: none;
}
:deep(.el-progress.is-success .el-progress-bar__inner) {
  background-color: #00f1a2;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.top-card {
  margin-bottom: 24px;
  :deep(.el-card__body) {
    padding-top: 0;
  }
}
.learnCate {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  li {
    width: 14%;
    height: 240px;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    padding: 20px 10px;
    box-sizing: border-box;
    overflow: hidden;
    .head {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 20px;
      img {
        width: 48px;
        height: 48px;
      }
      span {
        font-size: 14px;
        font-weight: bold;
        color: #595959;
      }
    }
    .titleBox {
      min-height: 82px;
      margin-bottom: 20px;
      .title {
        font-size: 14px;
        color: #595959;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 0 0 8px;
        cursor: pointer;
        &:hover {
          color: #2b82ff;
        }
      }
    }
    .lookMore {
      display: block;
      margin: 0 auto;
      font-size: 14px;
      color: #2b82ff;
      i {
        font-size: 14px;
      }
      &:hover {
        color: #57c7fb;
      }
    }
  }
}
.updateList {
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    cursor: pointer;
    .title {
      .el-tag {
        margin-right: 8px;
      }
      width: 60%;
      font-size: 14px;
      color: #595959;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      cursor: pointer;
    }
    .time {
      font-size: 12px;
      color: #8c8c8c;
    }
    &:hover {
      .title,
      .time {
        color: #2b82ff;
      }
    }
  }
}
.membercorn {
  position: absolute;
  top: -1px;
  right: -1px;
  width: 60px;
  height: auto !important;
}
@media screen and (max-width: 1440px) {
  .learnCate li .head img {
    width: 35px;
    height: 35px;
  }
  .learnCate li .head span {
    font-size: 12px;
  }
}
</style>
